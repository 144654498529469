<template>
  <div id="courier-detail-page">
    <vs-popup classContent="popup-example" :class="{full_width: editingItems}" :title="editingItems ? $t('edit') : `${$t('change')} ${$t('password')}`" :active.sync="showModal">
      <template v-if="!editingItems">  
        <div class="grid grid-cols-2 gap-4">
          <p>{{ $t('newPassword') }}: </p>
          <div>
            <vs-input type="password" v-validate="'required'" name="newPassword" ref="newPassword" class="w-full inputx mb-1" placeholder="Password" v-model="newPassword" />
            <span class="text-danger text-sm" v-show="errors.has('newPassword')">{{ errors.first('newPassword') }}</span>
          </div>
          <p>{{ $t('confirmPassword') }}: </p>
          <div>
            <vs-input type="password" v-validate="'required|confirmed:newPassword'" name="confirmation" class="w-full inputx mb-1" placeholder="Confirm Password" v-model="passwordConfirm" />
            <span class="text-danger text-sm" v-show="errors.has('confirmation')">{{ errors.first('confirmation') }}</span>
          </div>

        </div>
        <div class="flex mt-5">
          <vs-button @click="updatePassword()" color="primary" class="mt-5 ml-auto" type="filled">{{ $t('update') }}</vs-button>
        </div>
      </template>

      <div v-else class="">
        <div class="vx-row mb-2 mt-2">
          <div class="vx-col w-full pt-2">
            <span class="text-lg font-semibold">{{$t('products')}}:</span>
          </div>
          <div class="vx-col w-full">
            <v-select multiple :options="products" :label="`name_${$i18n.locale}`" name="products" placeholder="Select products" v-model="updatedItems.products" />
          </div>
        </div>
        <div class="vx-row mb-2 mt-2">
          <div class="vx-col w-full pt-2">
            <span class="text-lg font-semibold">{{$t('comboProducts')}}:</span>
          </div>
          <div class="vx-col w-full">
            <v-select multiple :options="collections" :label="`name_${$i18n.locale}`" name="combos" placeholder="Select combos" v-model="updatedItems.collections" />
          </div>
        </div>

        <div class="border border-solid border-grey-light rounded-lg mt-4 overflow-x-hidden overflow-y-auto">
          <h4 v-if="updatedItems.products.length" class="ml-2 mt-2">{{$t('products')}}</h4>
          <div class="vx-row m-1" v-for="(item, idx) in updatedItems.products" :key="`p-${idx}`">
            <div class="vx-col w-3/4">
              {{ item[`name_${$i18n.locale}`] }} / stock:<b>{{ item.stock_quantity }}</b>
            </div>
            <div class="vx-col w-1/4">
              <input v-validate="'required'" class="w-full p-1 rounded" :name="`qt_${item.id}`" :class="errors.has(`qt_${item.id}`) ? 'border-danger' : 'border-grey-light'" style="box-shadow:none;" placeholder="Quantity" v-model="item.quantity" />
            </div>
          </div>

          <h4 v-if="updatedItems.collections.length" class="ml-2">{{$t('comboProducts')}}</h4>
          <div class="vx-row m-1" v-for="(item, idx) in updatedItems.collections" :key="idx">
            <div class="vx-col w-3/4">
              {{ item[`name_${$i18n.locale}`] }}
            </div>
            <div class="vx-col w-1/4">
              <input v-validate="'required'" class="w-full p-1 rounded" :name="`qt_combo_${item.id}`" :class="errors.has(`qt_combo_${item.id}`) ? 'border-danger' : 'border-grey-light'" style="box-shadow:none;" placeholder="Quantity" v-model="item.quantity" />
            </div>
          </div>
        </div>
        <div class="flex">
          <vs-button @click="updateItems" color="primary" class="mt-5 ml-auto" type="filled">{{$t('update')}}</vs-button>
        </div>
      </div>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <vs-alert color="danger" :active.sync="isActive">
        <span>{{ $t('courier') }} {{ $t('isBlocked') }}. </span>
      </vs-alert>

      <vx-card class="mt-6">
        <vs-row class="mb-3">
          <h3 class="mr-auto">{{ courier.name }}</h3>
          <vs-button v-if="$acl.check('superadmin')" type="border" color="warning" @click.prevent="changePassword" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
            {{ $t('changePassword') }}
          </vs-button>
          <vs-button v-if="$acl.check('operator') && courier.isExpress && !courier.isBlocked " type="border" color="success" @click.prevent="changeItems" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            {{ $t('edit')+' '+$t('products') }}
          </vs-button>
          <vs-button v-if="$acl.check('superadmin') && courier.isBlocked" type="border" color="primary" @click.prevent="changeStatus(courier.uuid, courier.isBlocked)" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            <feather-icon icon="UnlockIcon" svgClasses="h-4 w-4" />
            {{ $t('unblock') }}
          </vs-button>
          <vs-button v-else-if="$acl.check('superadmin') && !courier.isBlocked && !items.products.length && !items.collections.length" type="border" color="danger" @click.prevent="changeStatus(courier.uuid, courier.isBlocked)" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            <feather-icon icon="LockIcon" svgClasses="h-4 w-4" />
            {{ $t('block') }}
          </vs-button>
          <router-link v-if="$acl.check('superadmin')" :to="{name: 'courierEdit', params: {id: courier.uuid}}" class="py-3 px-5  mb-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t('edit') }}</span>
          </router-link>
          <vs-button v-if="$acl.check('admin') && courier.cash" type="filled" color="primary" @click.prevent="cashWithdraw" class="py-3 px-5 mb-4 ml-4 rounded-lg">
            <feather-icon icon="DollarSignIcon" svgClasses="h-4 w-4" />
            {{ $t('cashWithdraw') }}
          </vs-button>
          <!-- <div v-if="$acl.check('admin')" class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteData(courier.uuid)">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base">Delete</span>
          </div> -->
        </vs-row>

        <vs-table stripe :data="courierProps">
          <template slot="thead">
            <vs-th>{{ $t('property') }}</vs-th>
            <vs-th>{{ $t('value') }}</vs-th>
          </template>
          <template>
            <vs-tr v-for="(prop, idx) in courierProps" :key="idx">
              <vs-td v-if="prop !== 'password'">
                <span class="uppercase font-medium">{{ $t(`props.${prop}`) }}</span>
              </vs-td>
              <vs-td v-if="prop === 'carrier_regions'">
                <div class="img flex flex-row">
                  <div v-for="(region, idx) in courier[prop]" :key="region.id" class="img-container mr-3">
                    <strong :title="JSON.stringify(region)">{{ region[`name_${$i18n.locale}`] }}</strong><span v-if="idx + 1 < courier[prop].length">,</span>
                  </div>
                </div>
              </vs-td>
              <vs-td v-else-if="courier[prop] === true || courier[prop] === false">
                <vs-chip :color="courier[prop] ? 'success' : 'danger'">
                  <feather-icon v-if="courier[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td v-else-if="prop === 'createdAt' || prop === 'updatedAt'">
                {{ new Date(courier[prop]).toLocaleString('ru-RU') }}
              </vs-td>
              <vs-td v-else-if="prop !== 'password'">
                {{ courier[prop] }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>

      <vx-card v-if="isMounted && items.products && (items.products.length || items.collections.length)" class="mt-5">
        <vs-row>
          <h3 class="mr-auto text-primary">{{ $t('localStock') }}: </h3>
          <vs-button v-if="$acl.check('superadmin')" type="border" color="danger" @click.prevent="clearStock" class="py-3 px-5 ml-auto rounded-lg">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
            Clear stock
          </vs-button>
        </vs-row>
        <h5 class="my-2">{{ $t('products') }}</h5>
        <vs-table hoverFlat stripe :data="items.products">
          <template slot="thead">
            <vs-th>{{ $t('fields.code') }}</vs-th>
            <vs-th>{{ $t('fields.title') }}</vs-th>
            <vs-th>{{ $t('quantity') }}</vs-th>
          </template>
          <template>
            <vs-tr v-for="(itm, idx) in items.products" :key="idx">
              <vs-td><span class="uppercase font-medium">{{itm.Carrierproducts.productId}} / {{itm.uuid}}</span></vs-td>
              <vs-td>{{ itm[`name_${$i18n.locale}`] }}</vs-td>
              <vs-td>{{ itm['Carrierproducts'].stock_quantity }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <h5 class="my-2">{{ $t('comboProducts') }}</h5>
        <vs-table hoverFlat stripe :data="items.collections">
          <template slot="thead">
            <vs-th>{{ $t('fields.code') }}</vs-th>
            <vs-th>{{ $t('fields.title') }}</vs-th>
            <vs-th>{{ $t('quantity') }}</vs-th>
          </template>
          <template>
            <vs-tr v-for="(itm, idx) in items.collections" :key="idx">
              <vs-td><span class="uppercase font-medium">{{itm.Carriercollections.collectionId}} / {{itm.uuid}}</span></vs-td>
              <vs-td> {{ itm[`name_${$i18n.locale}`] }}</vs-td>
              <vs-td>{{ itm['Carriercollections'].stock_quantity }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>

      <vx-card v-if="isMounted && orders.data.length" class="mt-5">
        <vs-table stripe hoverFlat ref="table" :data="data">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex">
              <h4 class="text-primary">{{ $t('deliveries') }}:</h4>
            </div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentx * itemsPerPage - (itemsPerPage - 1) }} - {{ orders.pagination.all - currentx * itemsPerPage > 0 ? currentx * itemsPerPage : orders.pagination.all }} of {{ orders.pagination.all }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="changeItemsPerPage(4)">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(15)">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(20)">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

          </div>

          <template slot="thead">
            <vs-th>{{ $t('fields.code') }}</vs-th>
            <vs-th>{{ $t('fields.user') }}</vs-th>
            <vs-th>{{ $t('fields.total') }}</vs-th>
            <vs-th>{{ $t('deliveryTime') }}</vs-th>
            <vs-th>{{ $t('deliveryType') }}</vs-th>
            <vs-th>{{ $t('orderPage.rating') }}</vs-th>
            <vs-th>{{ $t('status') }}</vs-th>
            <vs-th>{{ $t('props.createdAt') }}</vs-th>
          </template>


          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">

              <vs-td :data="data[indextr].code">
                <span class="font-medium">#{{ data[indextr].code }}</span>
              </vs-td>

              <vs-td :data="data[indextr]">
                {{ data[indextr].user_name }}
              </vs-td>

              <vs-td :data="data[indextr]">
                <strong>{{ data[indextr].total_price }}</strong>
              </vs-td>

              <vs-td :data="data[indextr].delivery_time">
                {{ data[indextr].delivery_time }}
              </vs-td>

              <vs-td :data="data[indextr].delivery_type">
                {{ data[indextr].delivery_type }} / {{ data[indextr].delivery_cost }} 
              </vs-td>

              <vs-td :data="data[indextr].rating">
                {{ data[indextr].rating }}
                <feather-icon icon="StarIcon" svgClasses="text-warning h-5 w-5" />
              </vs-td>

              <vs-td>
                <vs-chip :color="getOrderStatusColor(data[indextr].status)" class="product-order-status">{{ data[indextr].status | title }}</vs-chip>
              </vs-td>

              <vs-td :data="data[indextr].createdAt">
                {{ data[indextr].createdAt  }}
              </vs-td>

              <template class="expand-order bg-primary" slot="expand">
                <div class="con-expand-orders w-full pl-3">
                  <div class="con-btns-user flex items-center justify-between border-0 border-b border-solid border-grey-light pb-2 mb-2">
                    <div class="con-userx flex items-center justify-start">
                      <span class="pt-1"><strong>Order: </strong></span><h3>#{{ data[indextr].code }}</h3>
                    </div>
                    <div class="flex">
                      <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success text-primary" @click.stop="showPage(tr.id)" />
                      <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary text-primary" class="ml-2" @click.stop="editData(tr.uuid)" />
                      <feather-icon icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-primary text-primary" class="ml-2" @click.stop="changeStatus(tr.uuid, tr.isBlocked)" />
                    </div>
                  </div>
                  <div class="">
                    <p class="my-1"><strong>User name: </strong>{{ tr.user_name }}</p>
                    <p class="my-1"><strong>User phone: </strong>{{ tr.user_phone }}</p>
                    <p class="my-1"><strong>User address: </strong>{{ tr.address }}</p>
                  </div>
                </div>
              </template>
            </vs-tr>
          </template>
        </vs-table>
        <vs-row class="mt-4">
          <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
        </vs-row>
      </vx-card>
    </template>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      courier: {},
      items: {
        products: [],
        collections: []
      },
      updatedItems: {
        products: [],
        collections: []
      },
      orders: [],
      products: [],
      collections: [],
      showModal: false,
      isMounted: false,
      isLoading: true,
      newPassword: '',
      passwordConfirm: '',
      currentx: 1,
      itemsOffset: 0,
      itemsPerPage: 4,
      editingItems: false
    }
  },
  async created () {
    await this.fetchData()
    await this.fetchOrders()
    this.isMounted = true
  },
  computed: {
    isActive () { return this.courier.isBlocked },
    data ()     { return this.orders.data },
    pages ()    { return Math.ceil(this.orders.pagination.all / this.itemsPerPage) },
    courierProps () { return Object.keys(this.courier).filter(el => el !== 'device_token') }
  },
  methods: {
    changeItemsPerPage (count) {
      this.itemsPerPage = count
      this.fetchOrders()
    },
    paginateTo () {
      this.itemsOffset = this.itemsPerPage * (this.currentx - 1)
      this.fetchOrders()
    },
    getOrderStatusColor (status) {
      if (status === 'on_hold')   return 'warning'
      if (status === 'pending')   return 'warning'
      if (status === 'rejected')   return 'danger'
      if (status === 'delivered') return 'success'
      if (status === 'not_delivered') return 'danger'
      if (status === 'canceled')  return 'danger'
      return 'primary'
    },
    async fetchOrders () {
      await this.$http.get('/carriers/orders', {
        params: {
          carrierId: this.courier.id,
          limit: this.itemsPerPage,
          offset: this.itemsOffset
        }
      }).then(response => { this.orders = response.data })
    },
    async fetchProducts () {
      await this.$http.get('/products', {
        params: {
          type: 'express',
          isActive: true
        }
      }).then(response => {
        this.products = response.data.data
      })
    },
    async fetchCombos () {
      await this.$http.get('/collections', {
        params: { type: 'express', isActive: true }
      }).then(response => {
        this.collections = response.data.data
      })
    },
    async fetchData () {
      await this.$http.get(`/carriers/${this.$route.params.id}`).then(response => {
        this.courier = response.data
        this.isLoading = false
      })
      this.isLoading = false
      if (this.courier.isExpress) {
        await this.$http.get(`/carriers/items/${this.$route.params.id}`).then(response => {
          response.data.products = response.data.products.map(el => { return {...el, quantity: el['Carrierproducts'].stock_quantity} }) 
          response.data.collections = response.data.collections.map(el => { return {...el, quantity: el['Carriercollections'].stock_quantity} }) 
          this.items = response.data
        })
      }
    },
    async deleteData (uuid) {
      await this.$http.delete(`/couriers/delete/${uuid}`).then(() => {
        this.notify('success', 'Courier successfully deleted!')
        this.$router.push('/couriers')
      }).catch(err => {
        this.notify('error', err.response.data.message)
      })
    },
    changePassword () {
      this.newPassword = ''
      this.passwordConfirm = ''
      this.editingItems = false
      this.showModal = true
    },
    async changeItems () {
      await this.fetchProducts()
      await this.fetchCombos()
      this.updatedItems.products = this.items.products
      this.updatedItems.collections = this.items.collections
      this.editingItems = true
      this.showModal = true
    },
    updatePassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/carriers/edit/${this.courier.uuid}`, {
            password: this.newPassword
          }).then(() => {
            this.showModal = false
            this.notify('success', 'Courier password successfully updated!')
            this.fetchData()
          }).catch(err => {
            this.notify('error', err.message)
          })
        } else {
          this.notify('error', 'Form is not valid. Check and try again.')
        }
      })
    },
    updateItems () {
      this.$validator.validateAll().then(result => {
        if (result && (this.updatedItems.products.length || this.updatedItems.collections.length)) {
          const prods = this.updatedItems.products.map(el => { return {quantity: Number(el.quantity), productId: el.id || el['Carrierproducts'].productId || el.product_images.productId} })
          const combos = this.updatedItems.collections.map(el => { return {quantity: Number(el.quantity), collectionId: el.id || el['Carriercollections'].collectionId} })
          const formData = {
            carrierId: this.courier.id,
            items: [...prods, ...combos]
          }
          this.$http.patch('/carriers/edit-items', formData).then(() => {
            this.fetchData()
            this.editingItems = false
            this.showModal = false
            this.notify('success', 'Items add successfully!')
          }).catch(err => {
            this.notify('error', err.message)
          })
        } else {
          this.notify('error', 'Form is invalid. Please check again!')
        }
      })
    },
    cashWithdraw () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: this.$t('cashWithdraw'),
        text: `${this.$t('confirmCashWithdraw')} "${this.courier.username}"`,
        accept: this.withdrawCash,
        acceptText: this.$t('submit'),
        cancelText: this.$t('cancel')
      })
    },
    withdrawCash () {
      this.$http.patch(`/carriers/update-cash/${this.courier.uuid}`, {
        cash: 0,
        task_today: 0
      }).then(() => {
        this.fetchData()
      })
    },
    clearStock () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Deletion',
        text: `You are about to clear stock "${this.courier.username}"`,
        accept: this.stockClear,
        acceptText: 'Confirm'
      })
    },
    stockClear () {
      const formData = {
        carrierId: this.courier.id,
        items: []
      }
      this.$http.patch('/carriers/edit-items', formData).then(() => {
        this.fetchData()
        this.notify('success', 'Items deleted successfully!')
      }).catch(err => {
        this.notify('danger', err.message)
      })
    },
    async changeStatus (id, status) {
      await this.$http.patch(`/carriers/edit/${id}`, {
        isBlocked: !status
      }).then(() => {
        this.fetchData()
        this.notify('success', 'Courier status successfully changed!')
      }).catch(err => {
        this.notify('error', err.message)
      })
    },
    notify (status, msg) {
      if (status === 'success') {
        this.$vs.notify({
          title: 'Success',
          text: msg,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.full_width {
  .vs-popup {
    width: 90%;
  }
}
</style>